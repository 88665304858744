import { createContext, useContext } from "react";
import {
  BasicFaucetAppNetworkInfo,
  FaucetAppNetworkInfo,
} from "../../shared/models/types";

export interface NetworkInfo {
  currentNetworkAppConfig: BasicFaucetAppNetworkInfo;
  supportedAppConfigs: BasicFaucetAppNetworkInfo[];
}

const NetworkInfoContext = createContext<NetworkInfo | undefined>(undefined);

export interface NetworkInfoProviderProps {
  children: React.ReactNode;
  appNetwork: FaucetAppNetworkInfo;
  supportedNetworkConfigs: BasicFaucetAppNetworkInfo[];
}

export const NetworkInfoProvider = ({
  children,
  appNetwork,
  supportedNetworkConfigs,
}: NetworkInfoProviderProps) => {
  return (
    <NetworkInfoContext.Provider
      value={{
        currentNetworkAppConfig: {
          id: appNetwork.id,
          fullName: appNetwork.fullName,
          networkDisplayName: appNetwork.networkDisplayName,
          chainName: appNetwork.chainName,
          networkInfo: appNetwork.networkInfo,
        },
        supportedAppConfigs: supportedNetworkConfigs,
      }}
    >
      {children}
    </NetworkInfoContext.Provider>
  );
};

export default function useNetworkInfo(): NetworkInfo {
  const networkInfo = useContext(NetworkInfoContext);
  if (networkInfo) {
    return networkInfo;
  }
  throw Error("Missing NetworkInfoContext.Provider");
}
