import React, { memo } from "react";
import { CloseButton } from "react-bootstrap";
import { ReactComponent as PolyscanLogo } from "../../assets/img/polyscan-light.svg";
import { ReactComponent as EtherscanLogo } from "../../assets/img/etherscan-logo-light.svg";
import { ReactComponent as OptimismScanLogo } from "../../assets/img/optimism-scan-logo-light.svg";
import { ReactComponent as ArbitrumScanLogo } from "../../assets/img/arbitrum-explorer-logo-light.svg";
import { ReactComponent as BaseExplorerLogo } from "../../assets/img/base-explorer-light.svg";
import { ReactComponent as ZKsyncEraLogo } from "../../assets/img/zksync-era-logo-light.svg";
import FaucetTwitterComponent from "./FaucetTwitterComponent";
import chainrunner from "../../assets/img/chainrunner.png";
import boredape from "../../assets/img/boredape.png";
import coolcat from "../../assets/img/coolcat.png";
import punk from "../../assets/img/punk.png";
import boredape2 from "../../assets/img/boredape2.png";
import wow from "../../assets/img/wow.png";
import {
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
  BaseBlockChain,
  FaucetAppNetworkInfo,
} from "../../shared/models/types";
import Konami from "react-konami-code";
import "../../assets/css/Easter.css";
import useNetworkInfo from "../hooks/useNetworkInfo";
import { HelperStrings } from "../../shared/constants";

let GLOBAL_EE_ANIMATION_FRAME = -1;

export const EASTER_EGG_SUBJECTS = (
  network: string,
  tokenName: string,
): EasterEggSubjects[] => [
  {
    imageSrc: chainrunner,
    tagline: "Mega City awaits your smart contract deployment, Runner.",
  },
  {
    imageSrc: boredape,
    tagline: `Now you can donate your new ${network} ${tokenName} to the ape wildlife fund.`,
  },
  {
    imageSrc: boredape2,
    tagline: `Now you can donate your new ${network} ${tokenName} to the ape wildlife fund.`,
  },
  {
    imageSrc: coolcat,
    tagline: `Hello fren. Will you spend that ${network} ${tokenName} on your pet, or your next dapp?`,
  },
  {
    imageSrc: punk,
    tagline: `Here's to the misfits and the punks. Enjoy your ${network} ${tokenName}, builder.`,
  },
  {
    imageSrc: wow,
    tagline: `Use this ${network} ${tokenName} to bring representation, inclusivity, and equal opportunities to Web3!`,
  },
];

export const EASTER_EGG_DROP_COLORS = [
  "random",
  "gold",
  "red",
  "blue",
  "green",
];

function calculateBrightness(red: number, green: number, blue: number) {
  return Math.sqrt(
    red * red * 0.299 + green * green * 0.587 + blue * blue * 0.114,
  );
}

export interface EasterEggSubjects {
  tagline: string;
  imageSrc: string;
}

interface EasterEggRenderProps {
  onClickExit?: () => void;
  show: boolean;
  transactionUrl: string;
  config: EasterEggConfiguration;
  subject: EasterEggSubjects;
  dropFill: string;
  appNetworkInfo: FaucetAppNetworkInfo;
}

interface EasterEggConfiguration {
  initialVelocityCap: number;
  dropSizeCap: number;
  dropSizeFloor: number;
  detail: number;
  backgroundFill: string;
}

class Particle {
  x: number;
  y: number;
  speed: number;
  velocity: number;
  size: number;
  ctx: CanvasRenderingContext2D | null;
  color: string;

  constructor(
    public readonly canvas: HTMLCanvasElement,
    public readonly grid: number[][],
    public readonly easterEggData: EasterEggRenderProps,
  ) {
    this.x = Math.random() * canvas.clientWidth;
    this.y = 0;
    this.speed = 0;
    this.velocity = Math.random() * easterEggData.config.initialVelocityCap;
    this.size =
      Math.random() * easterEggData.config.dropSizeCap +
      easterEggData.config.dropSizeFloor;
    this.ctx = canvas.getContext("2d");
    if (this.easterEggData.dropFill === "random") {
      this.color = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
        Math.random() * 255
      }, 1)`;
    } else {
      this.color = this.easterEggData.dropFill;
    }
  }
  update() {
    const yI = Math.floor(
      this.y ? this.y : 0 / this.easterEggData.config.detail,
    );
    const xI = Math.floor(
      this.x ? this.x : 0 / this.easterEggData.config.detail,
    );
    if (this.grid.length > yI) {
      if (this.grid[yI].length > xI) {
        this.speed = this.grid[yI][xI];
      }
    }
    const movement = 2.5 - this.speed + this.velocity;
    this.y += movement;
    if (this.y >= this.canvas.height) {
      this.y = 0;
      this.x = Math.random() * this.canvas.width;
    }
  }
  draw() {
    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.fillStyle = this.color;
      this.ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
      this.ctx.fill();
    }
  }
}

function beginEasterEgg(
  canvas: HTMLCanvasElement,
  props: EasterEggRenderProps,
): void {
  const image = new Image();
  image.src = props.subject.imageSrc;
  image.addEventListener("load", function () {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("class", "stop-scrolling");
    window.scrollTo(0, 0);
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    const particlesArray: Particle[] = [];
    const numberOfParticles = 12000;
    const detail = 1;

    if (image.naturalHeight < image.naturalWidth) {
      const imageWidth = canvas.width * 0.5;
      const imageHeight =
        canvas.width * 0.5 * (image.naturalHeight / image.naturalWidth);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        (canvas.width - imageWidth) / 2,
        (canvas.height - imageHeight) / 2,
        imageWidth,
        imageHeight,
      );
    } else {
      const imageHeight = canvas.height * 0.5;
      const imageWidth =
        canvas.height * 0.5 * (image.naturalWidth / image.naturalHeight);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        (canvas.width - imageWidth) / 2,
        (canvas.height - imageHeight) / 2,
        imageWidth,
        imageHeight,
      );
    }

    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const grid: number[][] = [];
    for (let y = 0; y < canvas.height; y += detail) {
      const row = [];
      for (let x = 0; x < canvas.width; x += detail) {
        const red = pixels.data[y * 4 * pixels.width + x * 4];
        const green = pixels.data[y * 4 * pixels.width + (x * 4 + 1)];
        const blue = pixels.data[y * 4 * pixels.width + (x * 4 + 2)];
        const brightness = calculateBrightness(red, green, blue) / 100;
        row.push(brightness !== 0 ? brightness : 1);
      }
      grid.push(row);
    }

    function init() {
      for (let i = 0; i < numberOfParticles; i++) {
        particlesArray.push(new Particle(canvas, grid, props));
      }
    }
    init();

    function animate() {
      ctx.globalAlpha = 0.05;
      ctx.fillStyle = props.config.backgroundFill;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 0;
      for (let i = 0; i < particlesArray.length; i++) {
        particlesArray[i].update();
        ctx.globalAlpha = particlesArray[i].speed * 0.3;
        particlesArray[i].draw();
      }
      GLOBAL_EE_ANIMATION_FRAME = requestAnimationFrame(animate);
    }
    animate();
  });
}

function cleanUp(canvas: HTMLCanvasElement) {
  const body = document.getElementsByTagName("body")[0];
  body.setAttribute("class", "");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  cancelAnimationFrame(GLOBAL_EE_ANIMATION_FRAME);
}

export function EasterEggComponent(props: EasterEggRenderProps): JSX.Element {
  const canvas = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvasElement = canvas.current;
    if (props.show && canvas.current) {
      beginEasterEgg(canvas.current, props);
      window.addEventListener("resize", resize);
    }
    return () => {
      if (canvasElement) {
        window.removeEventListener("resize", resize);
        cleanUp(canvasElement);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  function resize(): void {
    const canvasElement = canvas.current;
    if (!canvasElement) {
      return;
    }
    cleanUp(canvasElement);
    beginEasterEgg(canvasElement, props);
  }

  const Logo = (() => {
    const chain = props.appNetworkInfo.networkInfo.chain;
    switch (chain) {
      case BaseBlockChain.MATIC:
        return PolyscanLogo;
      case BaseBlockChain.BASE:
        return BaseExplorerLogo;
      case BaseBlockChain.OPTIMISM:
        return OptimismScanLogo;
      case BaseBlockChain.ARBITRUM:
        return ArbitrumScanLogo;
      case BaseBlockChain.ZKSYNC:
        return ZKsyncEraLogo;
      case BaseBlockChain.ETH:
        return EtherscanLogo;
      case BaseBlockChain.WORLD_CHAIN:
      case BaseBlockChain.STARKNET:
        return null;
    }
  })();

  const faucetUrl = `${HelperStrings.ALCHEMY_COM_FAUCETS_URL}/${
    ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK[props.appNetworkInfo.id]
  }`;

  return (
    <div
      className="egg-content"
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
        top: 0,
        left: 0,
        display: "flex",
      }}
    >
      <canvas
        ref={canvas}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      ></canvas>
      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "start",
          padding: "30px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "1.5em",
            left: "1.5em",
          }}
          onClick={props.onClickExit}
        >
          <CloseButton
            className="egg-button"
            style={{
              backgroundSize: "1.5em",
              width: "1.5em",
              height: "1.5em",
            }}
            variant="white"
          />
        </span>
        <h3
          style={{
            font: "mono",
            color: "white",
            textAlign: "center",
            padding: "0px 40px 15px 40px",
          }}
        >
          {props.subject.tagline}
        </h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              margin: 0,
              fontWeight: "bold",
            }}
          >
            Support {faucetUrl} with a{" "}
          </p>
          <span style={{ height: 30, paddingLeft: 6 }}>
            <FaucetTwitterComponent
              size={"large"}
              appNetworkInfo={props.appNetworkInfo}
            />
          </span>
        </div>
        <p style={{ margin: 0 }}>or</p>
        <p style={{ margin: 0, fontWeight: "bold" }}>
          Follow your transaction {Logo ? "on " : null}
          <a
            href={props.transactionUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Logo ? (
              <Logo className="egg-action" style={{ height: 25, width: 120 }} />
            ) : (
              "here"
            )}
          </a>
        </p>
      </div>
    </div>
  );
}

const EasterEggContainer = memo(function EasterEggContainer({
  showEaster,
  responder,
  closeAction,
  transactionUrl,
  appNetwork,
}: {
  showEaster: boolean;
  responder: (showState: boolean) => void;
  closeAction: () => void;
  transactionUrl: string;
  appNetwork: FaucetAppNetworkInfo;
}) {
  const { currentNetworkAppConfig } = useNetworkInfo();
  const easter_egg_network_subject = EASTER_EGG_SUBJECTS(
    currentNetworkAppConfig.fullName,
    appNetwork.tokenName,
  );
  const easterEggSubject =
    easter_egg_network_subject[
      Math.floor(Math.random() * easter_egg_network_subject.length)
    ];
  const easterEggDropFill =
    EASTER_EGG_DROP_COLORS[
      Math.floor(Math.random() * EASTER_EGG_DROP_COLORS.length)
    ];
  return (
    <div className={showEaster ? "basket" : "basket hide"}>
      {!showEaster && (
        <Konami
          action={() => {
            responder(true);
          }}
        />
      )}
      <EasterEggComponent
        onClickExit={closeAction}
        show={showEaster}
        transactionUrl={transactionUrl}
        subject={easterEggSubject}
        dropFill={easterEggDropFill}
        config={{
          initialVelocityCap: 5,
          dropSizeCap: 1,
          dropSizeFloor: 0.1,
          detail: 1,
          backgroundFill: "rgba(0, 0, 0, 0.5)",
        }}
        appNetworkInfo={appNetwork}
      />
    </div>
  );
});

export default EasterEggContainer;
