import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaucetRequestStatus } from "../libraries/FaucetRequestTypes";
import FaucetLoadingSpinner from "./FaucetLoadingSpinner";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BaseBlockChain, RecaptchaClientType } from "../../shared/models/types";

export enum FaucetRequestAuthenticationState {
  UNAUTHENTICATED_AVAILABLE,
  UNAUTHENTICATED_RESTRICTED,
  AUTHENTICATED,
}

interface FaucetRequestComponentProps {
  addRequest: (reCAPTCHAValue: string) => void;
  addressChanged: (address: string) => void;
  status: FaucetRequestStatus;
  address: string;
  tokenName: string;
  chain: BaseBlockChain;
  requestUpsell?: JSX.Element;
  authenticationState: FaucetRequestAuthenticationState;
  recaptchaClientKey: string;
  recaptchaClientType: RecaptchaClientType;
}

interface FaucetRequestComponentState {
  captchaLoaded: boolean;
}

function placeHolderText(chain: BaseBlockChain) {
  const defaultText = "Enter Your Wallet Address (0x...)";
  return chain === BaseBlockChain.ETH
    ? `${defaultText} or ETH Mainnet ENS Domain`
    : defaultText;
}

export default class FaucetRequestComponent extends React.Component<
  FaucetRequestComponentProps,
  FaucetRequestComponentState
> {
  private recaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef();
  constructor(props: FaucetRequestComponentProps) {
    super(props);
    this.state = {
      captchaLoaded: false,
    };
  }
  recaptchaOnload() {
    this.setState({ captchaLoaded: true });
  }
  handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.props.addressChanged(e.target.value);
  }
  async handleRequestSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let alertText;
    if (this.recaptchaRef.current) {
      let reCAPTCHAValue;
      if (this.props.recaptchaClientType === RecaptchaClientType.INVISIBLE) {
        reCAPTCHAValue = await this.recaptchaRef.current.executeAsync();
        alertText =
          "Please refresh to complete our reCAPTCHA and validations to make this request.";
      } else {
        reCAPTCHAValue = this.recaptchaRef.current.getValue();
        alertText =
          "Please complete our reCAPTCHA and validations to make this request.";
      }

      // Get the visitor identifier when you need it.
      if (reCAPTCHAValue) {
        this.props.addRequest(reCAPTCHAValue);
        return;
      }
    }
    alert(alertText);
  }

  render(): JSX.Element {
    const handleInputChange = this.handleInputChange.bind(this);
    const handleRequestSubmit = this.handleRequestSubmit.bind(this);
    const recaptchaOnload = this.recaptchaOnload.bind(this);
    const disabled =
      this.props.authenticationState ===
        FaucetRequestAuthenticationState.UNAUTHENTICATED_RESTRICTED ||
      this.props.status === FaucetRequestStatus.PENDING ||
      !this.state.captchaLoaded;
    return (
      <span>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Form onSubmit={handleRequestSubmit}>
          <Row>
            <Col
              sm={12}
              md={9}
              style={{ marginBottom: 16, paddingLeft: 3, paddingRight: 3 }}
            >
              <Form.Control
                size="lg"
                className="alchemy-faucet-panel-input-text"
                type="text"
                placeholder={placeHolderText(this.props.chain)}
                onChange={handleInputChange}
                value={this.props.address}
                disabled={disabled}
              />
            </Col>
            <Col
              sm={12}
              md={3}
              style={{ marginBottom: 16, paddingLeft: 3, paddingRight: 3 }}
            >
              <Button
                size="lg"
                className="alchemy-faucet-button"
                variant="dark"
                type="submit"
                style={{ paddingLeft: 0, paddingRight: 0, margin: "auto" }}
                disabled={disabled}
              >
                {this.props.status === FaucetRequestStatus.PENDING ? (
                  <TransitionGroup className={"alchemy-faucet-table-body"}>
                    <CSSTransition
                      key={"empty"}
                      timeout={300}
                      classNames="faucet-request-item"
                    >
                      <FaucetLoadingSpinner
                        width={30}
                        height={30}
                        thickness={4}
                        margin={"auto"}
                        color={"white"}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                ) : (
                  <TransitionGroup className={"alchemy-faucet-table-body"}>
                    <CSSTransition
                      key={"empty"}
                      timeout={300}
                      classNames="faucet-request-item"
                    >
                      <span>Send Me {this.props.tokenName}</span>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
        {this.props.requestUpsell && (
          <Row>
            <Col style={{ paddingLeft: 3, paddingRight: 3 }} xs={12}>
              {this.props.requestUpsell}
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ paddingLeft: 3, paddingRight: 3 }} xs={12}>
            <ReCAPTCHA
              sitekey={this.props.recaptchaClientKey}
              ref={this.recaptchaRef}
              asyncScriptOnLoad={recaptchaOnload}
              size={this.props.recaptchaClientType}
              badge="bottomleft"
            />
          </Col>
        </Row>
      </span>
    );
  }
}
