import { HelperStrings } from "../../shared/constants";
import {
  BaseBlockChain,
  FaucetAppNetworkInfo,
} from "../../shared/models/types";

export function minimumBalanceFAQ(
  minBalanceRequired: string,
  networkInfo: FaucetAppNetworkInfo,
): JSX.Element {
  const innerString = HelperStrings.MIN_MAINNET_BALANCE_REQUIRED(
    minBalanceRequired,
    networkInfo.networkInfo.chain,
    networkInfo.networkInfo.network,
    networkInfo.tokenName,
  );
  return (
    <>
      <b>
        I get an error saying “
        <span dangerouslySetInnerHTML={{ __html: innerString }} />“ What is
        going on?
      </b>
      <p>
        This faucet is designed to provide test tokens to real-world developers
        who need to test their smart contracts and interact with the blockchain.
        In order to prevent abuse, we require developers to hold a minimum
        balance on the ETH mainnet in the same wallet address before they can
        receive test tokens.
      </p>
    </>
  );
}

export function needAdditionalOptimism(
  networkConfig: FaucetAppNetworkInfo,
): JSX.Element | null {
  if (networkConfig.networkInfo.chain !== BaseBlockChain.OPTIMISM) {
    return null;
  }

  const rateLimitHours = networkConfig.rateLimitDurationSeconds / 3600;

  return (
    <>
      <b id={"access-optimism-superchain"}>
        Need additional Optimism Sepolia ETH?
      </b>
      <p>
        You can request up to 1 additional test token every {rateLimitHours}{" "}
        hours using the{" "}
        <a
          href={HelperStrings.OPTIMISM_SUPER_CHAIN_FAUCET_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Optimism Superchain Faucet
        </a>
        !
        <br />
        The Superchain Faucet lets verified developers claim an additional 1 ETH
        on any Superchain network, every {rateLimitHours} hours. The faucet is
        free, fast, and does not require authentication.
      </p>
      <b>Here&apos;s how to access the Superchain Faucet:</b>
      <ol>
        <li>
          If you haven&apos;t already, add your{" "}
          <a
            href={HelperStrings.ALCHEMY_BILLING_UPDATE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            billing info to your Alchemy account
          </a>
          <br />
          <ul>
            <li style={{ listStyleType: "disc" }}>
              Requiring users to add their billing information provides a layer
              of verification that prevents bots, or users who do not intend on
              using the faucet, from being given an attestation. You do not need
              to make any purchases to receive the attestation & claim from the
              faucet.
            </li>
          </ul>
        </li>
        <li>
          Go to the{" "}
          <a
            href={HelperStrings.OPTIMISM_SUPER_CHAIN_FAUCET_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Optimism Superchain Faucet
          </a>{" "}
          and claim.
        </li>
      </ol>
    </>
  );
}

export const evmAddressFAQ: JSX.Element = (
  <>
    <b>
      I get an error saying “Your wallet address was missing from the request,
      so we did not send you any test token” even though I did copy my wallet
      address correctly. What is going on?
    </b>
    <p>
      An Ethereum-based wallet address is 42 characters long including the “0x”
      at the front. We only support wallets that follow this{" "}
      <a
        href={HelperStrings.EVM_ADDRESS_FAQ_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        spec
      </a>
      , so please make sure your wallet meets the criteria! We have seen a few
      users try to put Argent Wallets, but unfortunately we do not support that
      at this time.
    </p>
  </>
);

export const missingTokensFAQ: JSX.Element = (
  <>
    <b>
      The faucet confirmed that it sent me test tokens, but I still have not
      received them. Why is that?
    </b>
    <p>
      The time it takes for you to receive your test tokens may vary! This is
      because the network may be congested at this time, or the amount of gas we
      have set for these transactions may be a little low compared to other
      transactions that miners may choose to validate instead. If it has been
      over a few hours and you still have not received your testnet tokens,
      please fill out this{" "}
      <span
        dangerouslySetInnerHTML={{ __html: HelperStrings.EMBEDDED_TYPEFORM }}
      />{" "}
      and we will send some!
    </p>
  </>
);

export const captchaFailFAQ: JSX.Element = (
  <>
    <b>
      I have been failing the Google Captcha that pops-up when I request test
      tokens. What do I do?
    </b>
    <p>
      This happens when you are using a VPN, and that is a Google blocker which
      we do not control. We recommend not using a VPN if that is the case.
    </p>
  </>
);

export function failedAbuseCheckFAQ(): JSX.Element {
  return (
    <>
      <b>
        When signing up for an Alchemy account, I get an error saying “Failed
        abuse check. Please try a different email or Google login. Otherwise,
        please fill out{" "}
        <a
          href="https://alchemyapi.typeform.com/to/AScaKcSU"
          target="_blank"
          rel="noopener noreferrer"
        >
          this form
        </a>{" "}
        with details about your use case for Alchemy and our team will review!.”
        What should I do?
      </b>
      <p>
        We added that additional check when you sign up to make sure you are
        human, not a bot! Please fill out{" "}
        <a
          href="https://alchemyapi.typeform.com/to/AScaKcSU"
          target="_blank"
          rel="noopener noreferrer"
        >
          this form
        </a>{" "}
        with details about your use case for Alchemy and our team will do a
        human review to verify account!
      </p>
    </>
  );
}

export const cookiesEnabledFAQ: JSX.Element = (
  <>
    <b>I got a “403 error.” What does that mean? What should I do?</b>
    <p>
      A 403 error is usually has to do with the browser not having cookies
      enabled. This just means your request did not pass our validations. Please
      enable browser cookies, refresh the page and try again!
    </p>
  </>
);
