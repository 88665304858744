import {
  BaseBlockChain,
  FaucetRequestNetwork,
  FaucetTransferTxn,
  HUMANIZED_BLOCK_CHAIN_NAME,
} from "./models/types";
import Utils from "./frontendUtils";

export const UrlPaths = {
  API_V1: {
    REQUEST_TRANSFER_PATH: "/api/v1/transfer",
    REQUEST_TRANSFER_STATUS_PATH: "/api/v1/transferStatus",
    REQUEST_AUTH_CHECK: "/api/v1/user",
    REQUEST_USER_PROPERTIES: "/api/v1/user/properties",
    REQUEST_SERVER_DATA: "/api/v1/serverData",
    HOOKS: {
      BASE_MATCH: "/api/v1/hooks/*",
      MINED: "/api/v1/hooks/mined",
      DROPPED: "/api/v1/hooks/dropped",
    },
    EAS_ATTESTATION: "/api/v1/eas/attestation",
  },
  HEALTH: "/health",
  APP_HEALTH: "/app_health",
  ROOT: "/",
  SERVER_DATA_KEY: "__SERVER_DATA__",
  LOCAL_DEV_SERVER_URL: "http://127.0.0.1:5001/",
};

export const ZERO_X = "0x";

export const HelperStrings = {
  TESTNET_DOC_URL: "https://www.alchemy.com/overviews/what-are-testnets",
  EVM_ADDRESS_FAQ_URL:
    "https://info.etherscan.com/what-is-an-ethereum-address/",
  ALCHEMY_BILLING_UPDATE_URL: "https://dashboard.alchemy.com/settings/billing",
  OPTIMISM_SUPER_CHAIN_FAUCET_URL: "https://app.optimism.io/faucet",
  ALCHEMY_COM_FAUCETS_URL: "https://alchemy.com/faucets",
  ALCHEMY_FAUCETS_URL: "https://faucets.alchemy.com",
  SUPPORT_EMAIL: "support@alchemy.com",
  DISCORD_URL: "https://alchemy.com/discord",
  TYPEFORM_URL: "https://alchemyapi.typeform.com/faucetfeedback",
  EMBEDDED_TYPEFORM: `<a class="faucet-banner-link" target="_blank" rel="noopener noreferrer" href=https://alchemyapi.typeform.com/faucetfeedback>form</a>`,
  ONBOARDING_ERROR_MSG: "Please finish setting up your Alchemy account",
  OG_FAUCET_TITLE: function (fullName: string): string {
    return `${fullName} Faucet`;
  },
  OG_FAUCET_DESCRIPTION: function (fullName: string): string {
    return `A fast and reliable ${fullName} testnet faucet for blockchain developers.`;
  },
  OG_IMAGE_URL: function (
    chain: BaseBlockChain,
    network: FaucetRequestNetwork,
  ): string {
    return `${this.ALCHEMY_FAUCETS_URL}/${HUMANIZED_BLOCK_CHAIN_NAME[chain]}-${network}-open-graph.png`;
  },
  UNAVAILABLE_MESSAGE: function (network: string): string {
    return (
      `Requests for ${network} network are currently unavailable. The team has been notified.` +
      ` Please reach out to us on our discord or go to ${this.TYPEFORM_URL} to provide feedback.`
    );
  },
  UNAVAILABLE_MESSAGE_DANGEROUS_HTML: function (network: string): string {
    return (
      `Requests for ${network} network are currently unavailable. The team has been notified.` +
      ` Please reach out to us on our <a class="faucet-banner-link" target="_blank" rel="noopener noreferrer" href="${this.DISCORD_URL}">Discord</a>,` +
      ` or fillout this ${this.EMBEDDED_TYPEFORM} to share your issue.`
    );
  },
  RESTRICTED_ADDRESS_MESSAGE: function (
    amount: string,
    rateLimitHours: number,
  ): string {
    return `Sorry! To be fair to all developers, we only send ${amount} every ${rateLimitHours} hours. Please try again after ${rateLimitHours} hours from your original request.`;
  },
  AUTHENTICATION_REQUIRED: function (): string {
    return `Sorry! You are required to be authorized with an Alchemy account to request from the faucet.`;
  },
  UNKNOWN_ERROR_MESSAGE: function (): string {
    return `Oops! This request failed and we have notified the team. Please try again later or reach out to us on our Discord or or go to ${this.TYPEFORM_URL} to provide feedback.`;
  },
  UNKNOWN_ERROR_MESSAGE_DANGEROUS_HTML: function (): string {
    return (
      `Oops! This request failed and we have notified the team. Please try again later or reach out to` +
      ` us on our <a class="faucet-banner-link" target="_blank" rel="noopener noreferrer" href="${this.DISCORD_URL}">Discord</a> or fillout this ${this.EMBEDDED_TYPEFORM} to share your feedback or issue.`
    );
  },
  MISSING_WALLET_ADDRESS: function (): string {
    return `Your wallet address was missing from the request, so we didn't send you any test token. Please enter your address and request again to use the faucet.`;
  },
  INVALID_WALLET_ADDRESS: function (): string {
    return `The wallet address you entered was invalid. Please check the address and confirm it is in the correct format before trying your request again.`;
  },
  INVALID_GENERIC: function (): string {
    return `Your address did not pass our validations, so we could not complete your request. Please refresh the page and try again.`;
  },
  INVALID_CAPTCHA_VALUE: function (): string {
    return `Your request did not complete the reCAPTCHA as expected, so we could not complete your request. Please refresh the page and try again.`;
  },
  INVALID_HOOK_AUTH: function (): string {
    return `"Invalid or Unsupported Webhook Authentication"`;
  },
  INVALID_HOOK_PARAMS: function (): string {
    return `"Invalid or Unsupported Webhook Parameters"`;
  },
  INVALID_CONTRACT_ADDRESS: function (network: string): string {
    return `This ${network} faucet does not support the smart contract address provided. Please use a valid wallet address and try again.`;
  },
  INVALID_REQUEST_STATUS: function (
    requestId: string,
    txnData: FaucetTransferTxn,
  ): string {
    return `Request ${requestId} is processing or already processed and has a status of ${txnData.status}. Please reach out to us on our Discord or or go to ${this.TYPEFORM_URL} to provide feedback.`;
  },
  INVALID_REQUEST_STATUS_DANGEROUS_HTML: function (
    requestId: string,
    txnData: FaucetTransferTxn,
  ): string {
    return `Request ${requestId} is processing or already processed and has a status of ${txnData.status}. Please reach out to us on our <a class="faucet-banner-link" target="_blank" rel="noopener noreferrer" href="${this.DISCORD_URL}">Discord</a> or fillout this ${this.EMBEDDED_TYPEFORM} to share your feedback or issue.`;
  },
  NETWORK_DOWN: function (): string {
    return `It is not possible to execute requests at this time.`;
  },

  MIN_MAINNET_BALANCE_REQUIRED: function (
    minValue: string,
    chain: BaseBlockChain,
    network: FaucetRequestNetwork,
    tokenName: string,
  ): string {
    const getTokenLink = {
      [BaseBlockChain.MATIC]: "https://polygon.technology/matic-token",
      [BaseBlockChain.ETH]: "https://ethereum.org/en/get-eth/",
      [BaseBlockChain.BASE]: "https://bridge.base.org/deposit",
      [BaseBlockChain.OPTIMISM]: "https://app.optimism.io/bridge/deposit",
      [BaseBlockChain.ARBITRUM]: "https://bridge.arbitrum.io/",
      [BaseBlockChain.STARKNET]: "https://starkgate.starknet.io/",
      [BaseBlockChain.ZKSYNC]: "https://portal.zksync.io/bridge/",
      [BaseBlockChain.WORLD_CHAIN]:
        "https://worldchain-mainnet-bridge.alchemy.com/",
    }[chain];
    const tokenFullName = `${Utils.toUpperCase(chain)} ${tokenName}`;

    return `To receive ${Utils.toUpperCase(chain)} ${Utils.toUpperCase(
      network,
    )} ${tokenName}, your wallet must have a minimum balance of ${minValue} on ${Utils.toUpperCase(
      chain,
    )} mainnet. Please add ${tokenFullName} to your wallet and try again. <a class="faucet-banner-link" target="_blank" rel="noopener noreferrer" href=${getTokenLink}>How do I add ${tokenFullName} to my wallet?</a>`;
  },
};
