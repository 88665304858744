import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactComponent as EthIcon } from "../../assets/img/test_eth_icon.svg";
import { ReactComponent as MaticIcon } from "../../assets/img/test_matic_icon.svg";
import TimeAgo from "react-timeago";
import React from "react";
import { FaucetRequestItem } from "../libraries/FaucetRequestTypes";
import { Col, Container, Row } from "react-bootstrap";
import { BaseBlockChain } from "../../shared/models/types";

interface FaucetRequestTableComponentProps {
  items: FaucetRequestItem[];
  chain: BaseBlockChain;
}

function getChainIcon(chain: BaseBlockChain): JSX.Element {
  switch (chain) {
    case BaseBlockChain.ARBITRUM:
    case BaseBlockChain.OPTIMISM:
    case BaseBlockChain.BASE:
    case BaseBlockChain.STARKNET:
    case BaseBlockChain.ETH:
    case BaseBlockChain.ZKSYNC:
    case BaseBlockChain.WORLD_CHAIN:
      return (
        <EthIcon className="d-sm-inline d-none" style={{ marginRight: 8 }} />
      );
    case BaseBlockChain.MATIC:
      return (
        <MaticIcon className="d-sm-inline d-none" style={{ marginRight: 8 }} />
      );
  }
}

function generateTableRow(
  item: FaucetRequestItem,
  chain: BaseBlockChain,
): JSX.Element {
  return (
    <CSSTransition
      key={item.clientRequestId}
      timeout={300}
      classNames="faucet-request-item"
    >
      <Row>
        <Col md={10} sm={12} className="alchemy-faucet-table-data">
          <p>
            {getChainIcon(chain)}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.response.transactionURL}
            >
              {item.response.transactionHash}
            </a>
          </p>
        </Col>
        <Col className="alchemy-faucet-table-data d-md-block d-sm-none d-none">
          <TimeAgo date={item.requestTime} />
        </Col>
      </Row>
    </CSSTransition>
  );
}

export default class FaucetRequestTable extends React.Component<FaucetRequestTableComponentProps> {
  render(): JSX.Element {
    return (
      <Container className="alchemy-faucet-table" style={{ padding: 0 }}>
        <Row className="alchemy-faucet-table-header">
          <Col sm={10} xs={12} className="alchemy-faucet-table-header-data">
            Your Transactions
          </Col>
          <Col className="alchemy-faucet-table-header-data d-md-block d-sm-none d-none">
            Time
          </Col>
        </Row>
        <TransitionGroup className={"alchemy-faucet-table-body"}>
          {this.props.items.length >= 1 ? (
            this.props.items.map((item: FaucetRequestItem) => {
              return generateTableRow(item, this.props.chain);
            })
          ) : (
            <CSSTransition
              key={"empty"}
              timeout={300}
              classNames="faucet-request-item"
            >
              <Row>
                <Col className="alchemy-faucet-table-data">-</Col>
              </Row>
            </CSSTransition>
          )}
        </TransitionGroup>
      </Container>
    );
  }
}
