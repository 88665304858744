import { useEffect, useState } from "react";
import {
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
  BaseBlockChain,
  FaucetChainNetworkKey,
  FaucetClientServerData,
  FaucetRequestNetwork,
  FaucetRequestResponse,
  FaucetResponseStatus,
} from "../shared/models/types";
import {
  CLIENT_REQUEST_ID_PREFIX,
  FaucetHealthResponse,
  FaucetRequestItem,
  FaucetRequestStatus,
} from "./libraries/FaucetRequestTypes";
import { v4 as uuidv4 } from "uuid";
import FaucetLocalStorage from "./libraries/FaucetLocalStorage";
import FaucetTransferClient from "./libraries/FaucetTransferClient";
import EasterEggContainer from "./components/EasterEggComponent";
import { AuthState } from "./components/AuthCheckComponent";
import useAuth from "./libraries/AuthCheck";
import FaucetAppContent from "./FaucetAppContent";
import {
  ClassicEthFAQContent,
  ClassicMumbaiFAQContent,
} from "./helpers/FAQContent";
import { ClassicSingleFaucetNavigationBar } from "./components/NavigationBar";
import ClassicFaucetAppContainer from "./ClassicAppContainer";
import { FaucetRequestAuthenticationState } from "./components/FaucetRequestComponent";
import { getServerData } from "./libraries/FaucetServerData";
import FaucetLoadingSpinner from "./components/FaucetLoadingSpinner";
import { FaucetRequestUpsell } from "./components/FaucetRequestUpsells";
import { isActiveTeam } from "./libraries/Util";
import UpsellModal from "./components/UpdsellModal";
import useNetworkInfo, { NetworkInfoProvider } from "./hooks/useNetworkInfo";
import OptimismSuccessModal from "./components/success/OptimismSuccessModal";
import { OptimismSuperchainAnnouncementBanner } from "./components/FaucetAnnouncementBanner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface ApplicationProps {
  serverData: FaucetClientServerData;
}

export default function FaucetApp() {
  const { chainNetwork } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [serverData, setServerData] = useState<FaucetClientServerData>();
  useEffect(() => {
    getServerData(chainNetwork as FaucetChainNetworkKey)
      .then((data) => {
        // Replace URL with current faucet namespace and keep search params if they exist since the auth redirect need them.
        navigate(
          `/faucets/${
            ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK[
              data.APP_NETWORK_INFO.id
            ]
          }?${searchParams.toString()}`,
          { replace: true },
        );
        setServerData(data);
      })
      .catch(console.error);
  }, [navigate, chainNetwork, searchParams]);
  return serverData ? (
    <NetworkInfoProvider
      appNetwork={serverData.APP_NETWORK_INFO}
      supportedNetworkConfigs={serverData.SUPPORTED_NETWORK_CONFIGS}
    >
      <FaucetLoadedApp serverData={serverData} />
    </NetworkInfoProvider>
  ) : (
    <FaucetLoadingSpinner
      color={"white"}
      width={30}
      height={30}
      thickness={3}
      margin={"50%"}
    />
  );
}

function FaucetLoadedApp(props: ApplicationProps) {
  const [toAddress, setToAddress] = useState<string>("");
  const [requestItems, setRequestItems] = useState<FaucetRequestItem[]>(
    FaucetLocalStorage.getRequests(props.serverData.APP_NETWORK_INFO.id),
  );
  const [requestStatus, setRequestStatus] = useState<FaucetRequestStatus>(
    FaucetRequestStatus.IDLE,
  );
  const [healthData, setHealthData] = useState<FaucetHealthResponse>();
  const [showEaster, setShowEaster] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const authProps = useAuth(
    props.serverData.AUTH_URL,
    props.serverData.API_SERVER_URL,
    props.serverData.ONBOARDING_URL,
    props.serverData.APP_NETWORK_INFO.affiliateReferral,
  );
  const appNetwork = props.serverData.APP_NETWORK_INFO;
  const [bannerInnerHtml, setBannerInnerHtml] = useState<string | undefined>(
    () => {
      if (!appNetwork) {
        return undefined;
      }

      if (appNetwork.noAuthBannerMessage !== "") {
        return appNetwork.noAuthBannerMessage;
      } else if (appNetwork.bannerMessage !== "") {
        return appNetwork.bannerMessage;
      }

      return undefined;
    },
  );

  useEffect(() => {
    async function healthResponsePoll() {
      const response = await FaucetTransferClient.requestHealth(
        appNetwork.id,
        props.serverData.API_SERVER_URL,
      );
      setHealthData(response);
    }

    void healthResponsePoll();
  }, [appNetwork.id, props.serverData.API_SERVER_URL]);

  useEffect(() => {
    if (
      authProps.authState === AuthState.AUTHENTICATED &&
      bannerInnerHtml === appNetwork.noAuthBannerMessage
    ) {
      setBannerInnerHtml(
        appNetwork.bannerMessage !== "" ? appNetwork.bannerMessage : undefined,
      );
    }
  }, [
    appNetwork.bannerMessage,
    appNetwork.noAuthBannerMessage,
    authProps,
    bannerInnerHtml,
  ]);

  function onRequestResponse(
    clientRequestId: string,
    serverResponse: FaucetRequestResponse,
  ) {
    switch (serverResponse.responseStatus) {
      case FaucetResponseStatus.SUCCESS: {
        const requestItem = {
          requestTime: new Date(),
          toAddress: serverResponse.toAddress,
          clientRequestId: clientRequestId,
          response: serverResponse,
        };
        const storageResult = FaucetLocalStorage.addRequest(
          appNetwork.id,
          requestItem,
        );
        if (!storageResult) {
          console.error(
            `An error occurred storing the result locally: (${requestItem.clientRequestId})`,
          );
        }
        requestItems.unshift(requestItem);
        if (appNetwork.networkInfo.chain === BaseBlockChain.OPTIMISM) {
          setShowSuccessModal(true);
        } else {
          setShowEaster(true);
        }

        setToAddress("");
        setRequestItems(requestItems);
        setRequestStatus(FaucetRequestStatus.IDLE);
        return;
      }
      case FaucetResponseStatus.ERROR: {
        const bannerInnerHtml =
          serverResponse.dangerous_htmlString ?? serverResponse.message;
        setShowEaster(false);
        setBannerInnerHtml(bannerInnerHtml);
        setRequestStatus(FaucetRequestStatus.IDLE);
        return;
      }
    }
  }

  const [showUpsell, setShowUpsell] = useState(false);

  const { currentNetworkAppConfig } = useNetworkInfo();

  function onRequestTransfer(reCAPTCHAValue: string) {
    const clientRequestId: string = CLIENT_REQUEST_ID_PREFIX.concat(uuidv4());
    setRequestStatus(FaucetRequestStatus.PENDING);
    setBannerInnerHtml(undefined);
    void FaucetTransferClient.requestTransfer(
      appNetwork.id,
      props.serverData.API_SERVER_URL,
      {
        toAddress: toAddress,
        clientRequestId: clientRequestId,
        reCAPTCHAValue: reCAPTCHAValue,
        alchemyUserId:
          authProps.authState === AuthState.AUTHENTICATED
            ? authProps.userId
            : "",
      },
    ).then((result) => {
      onRequestResponse(result.clientRequestId, result.serverResponse);
    });
  }

  const userIsActiveTeam =
    authProps.authState === AuthState.AUTHENTICATED &&
    isActiveTeam(authProps.userProperties);

  const amount =
    authProps.authState === AuthState.AUTHENTICATED
      ? userIsActiveTeam && appNetwork.activeTeamUpsellEnabled
        ? appNetwork.activeTeamDripAmount
        : appNetwork.authDripAmount
      : appNetwork.nonAuthDripAmount;
  const requestAuthState =
    authProps.authState === AuthState.AUTHENTICATED
      ? FaucetRequestAuthenticationState.AUTHENTICATED
      : appNetwork.requiresAuthentication
        ? FaucetRequestAuthenticationState.UNAUTHENTICATED_RESTRICTED
        : FaucetRequestAuthenticationState.UNAUTHENTICATED_AVAILABLE;
  const network = appNetwork.networkInfo.network;
  const authDripAmount =
    authProps.authState === AuthState.AUTHENTICATED &&
    userIsActiveTeam &&
    appNetwork.activeTeamUpsellEnabled
      ? appNetwork.activeTeamDripAmount
      : appNetwork.authDripAmount;
  const classicAppData = {
    serverData: props.serverData,
    title: `${currentNetworkAppConfig.fullName} FAUCET`,
    subtitle: `Fast and reliable. ${amount}/day.`,
    toAddress: toAddress,
    requestItems: requestItems,
    requestStatus: requestStatus,
    faq:
      network === FaucetRequestNetwork.MUMBAI ? (
        <ClassicMumbaiFAQContent
          nonAuthDrip={appNetwork.nonAuthDripAmount}
          authDrip={authDripAmount}
          minMainnetBalance={appNetwork.minRequiredMainnetBalance}
          referral={appNetwork.affiliateReferral}
          loginRedirect={authProps.loginRedirect}
          networkInfo={appNetwork}
          reserveWalletAddress={appNetwork.reserveWalletAddress}
        />
      ) : (
        <ClassicEthFAQContent
          testnet={appNetwork.networkDisplayName}
          nonAuthDrip={appNetwork.nonAuthDripAmount}
          authDrip={authDripAmount}
          minMainnetBalance={appNetwork.minRequiredMainnetBalance}
          referral={appNetwork.affiliateReferral}
          loginRedirect={authProps.loginRedirect}
          networkInfo={appNetwork}
          reserveWalletAddress={appNetwork.reserveWalletAddress}
        />
      ),
    affiliateReferral: appNetwork.affiliateReferral,
    tokenName: appNetwork.tokenName,
    chain: appNetwork.networkInfo.chain,
    requestAuthState: requestAuthState,
    requestUpsell: (
      <FaucetRequestUpsell appNetwork={appNetwork} authCheck={authProps} />
    ),
    bannerInnerHtml: bannerInnerHtml,
    healthData: healthData,
    authData: authProps,
    stage: undefined,
  };

  const classicAppActions = {
    setAddress: setToAddress,
    didRequest: onRequestTransfer,
    bannerClose: () => {
      setBannerInnerHtml(undefined);
    },
  };

  const showUpsellIfNeeded = () => {
    // If this user is logged in but not active
    if (
      !userIsActiveTeam &&
      authProps.authState === AuthState.AUTHENTICATED &&
      appNetwork.activeTeamUpsellEnabled
    ) {
      setShowUpsell(true);
    }
  };

  return (
    <>
      <ClassicFaucetAppContainer className="alchemy-app">
        <ClassicSingleFaucetNavigationBar
          affiliateReferral={appNetwork.affiliateReferral}
          healthData={healthData}
          authData={authProps}
        />
        {appNetwork.networkInfo.chain === BaseBlockChain.OPTIMISM && (
          <OptimismSuperchainAnnouncementBanner />
        )}
        <FaucetAppContent
          renderData={classicAppData}
          actions={classicAppActions}
        />
      </ClassicFaucetAppContainer>
      <UpsellModal
        appNetwork={appNetwork}
        authCheck={authProps}
        show={showUpsell}
        handleClose={() => setShowUpsell(false)}
      />
      {appNetwork.networkInfo.chain === BaseBlockChain.OPTIMISM && (
        <OptimismSuccessModal
          serverData={props.serverData}
          appNetwork={appNetwork}
          requestItems={requestItems}
          show={showSuccessModal}
          handleClose={() => {
            setShowSuccessModal(false);
            showUpsellIfNeeded();
          }}
        />
      )}
      <EasterEggContainer
        showEaster={showEaster}
        responder={setShowEaster}
        closeAction={() => {
          setShowEaster(false);
          showUpsellIfNeeded();
        }}
        transactionUrl={
          requestItems.length > 0
            ? requestItems[0].response.transactionURL
            : appNetwork.transactionUrl
        }
        appNetwork={appNetwork}
      />
    </>
  );
}
