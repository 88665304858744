import { memo, ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { FaucetAppNetworkInfo } from "../../shared/models/types";
import { getDeterministicNewDayElement } from "../libraries/Util";
import { AuthCheckProps, AuthState } from "./AuthCheckComponent";

interface UpsellModalProps {
  appNetwork: FaucetAppNetworkInfo;
  authCheck: AuthCheckProps;
  show: boolean;
  handleClose: () => void;
}

interface UpsellTextAndUrl {
  body: ReactNode;
  url: string;
  cta: string;
  emoji?: ReactNode;
}

const generateHeading = (network: string, token: string, userId: number) => {
  const headings = [
    `Need a way to use your ${network} ${token}?`,
    `Use that ${network} ${token}!`,
    `How to use that ${network} ${token}!`,
  ];
  const modular = getDeterministicNewDayElement(userId);
  return headings[modular % headings.length];
};

const generateUpsell = (
  appNetwork: FaucetAppNetworkInfo,
  userId: number,
): UpsellTextAndUrl => {
  const upsells = [
    {
      body: (
        <div className={"alchemy-faucet-upsell-modal-body"}>
          <p style={{ marginLeft: "10px", textAlign: "center" }}>
            The Alchemy SDK is the easiest way to connect your dApp to the
            blockchain.
          </p>
          <video
            src={
              "https://static.alchemyapi.io/videos/marketing/alchemy_sdk_demo.mp4"
            }
            width="90%"
            autoPlay={true}
            loop={false}
            muted={true}
            style={{ display: "block", margin: "auto", paddingBottom: "12px" }}
          />
          <p style={{ marginLeft: "10px", textAlign: "center" }}>
            Send your first request on the{" "}
            <span style={{ textTransform: "capitalize" }}>
              {appNetwork.networkInfo.network} Testnet
            </span>
          </p>
        </div>
      ),
      url: "https://www.alchemy.com/sdk",
      cta: "Try Now",
      emoji: <span>&#128073; </span>,
    },
    {
      body: (
        <div className={"alchemy-faucet-upsell-modal-body"}>
          <p>Alchemy Notify gets you real-time push notifications.</p>
          <img
            src={
              "https://static.alchemyapi.io/images/marketing/alchemy_notify_banner.png"
            }
            width="90%"
            alt={"Alchemy Notify Banner"}
            style={{ display: "block", margin: "auto", paddingBottom: "12px" }}
          />
          <p>
            Set up your first webhook and get notified for any blockchain event!
          </p>
        </div>
      ),
      url: "https://dashboard.alchemyapi.io/notify",
      cta: "Set Up Now",
      emoji: <span>&#9889; </span>,
    },
    {
      body: (
        <div className={"alchemy-faucet-upsell-modal-body"}>
          <p>
            Alchemy&apos;s NFT API lets you find, verify, and display any NFT!
          </p>
          <video
            src={
              "https://static.alchemyapi.io/videos/marketing/alchemy_nft_api1.mp4"
            }
            width="90%"
            autoPlay={true}
            loop={false}
            muted={true}
            style={{ display: "block", margin: "auto", paddingBottom: "12px" }}
          />
          <p style={{ marginLeft: "10px", textAlign: "center" }}>
            Send your first request!
          </p>
        </div>
      ),
      url: "https://www.alchemy.com/nft-api",
      cta: "Send NFT Request Now",
      emoji: <span>&#128064; </span>,
    },
    {
      body: (
        <div className={"alchemy-faucet-upsell-modal-body"}>
          <p>
            The most powerful set of web3 development tools to build and scale
            your dApp with ease.
          </p>
          <video
            src={
              "https://static.alchemyapi.io/videos/marketing/alchemy_rpc.mp4"
            }
            width="90%"
            autoPlay={true}
            loop={false}
            muted={true}
            style={{ display: "block", margin: "auto", paddingBottom: "12px" }}
          />
          <p style={{ marginLeft: "10px", textAlign: "center" }}>
            Use Alchemy for the fastest, most accurate RPC requests!
          </p>
        </div>
      ),
      url: "https://dashboard.alchemyapi.io/",
      cta: "Send Requests Now",
      emoji: <span>&#128064; </span>,
    },
  ];

  const modular = getDeterministicNewDayElement(userId);
  return upsells[modular % upsells.length];
};

const UpsellModal = memo(function UpsellModal({
  appNetwork,
  authCheck,
  show,
  handleClose,
}: UpsellModalProps) {
  const userId =
    authCheck.authState === AuthState.AUTHENTICATED
      ? parseInt(authCheck.userId)
      : 0;
  const { body, url, cta, emoji } = generateUpsell(appNetwork, userId) || {
    body: <div />,
    url: "#",
  };
  const linkAction = (url: string) => window.open(url, "_blank");
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="justify-content-center"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title w-100 text-center"
            style={{ textTransform: "capitalize" }}
          >
            {generateHeading(
              appNetwork.networkInfo.network,
              appNetwork.tokenName,
              userId,
            )}{" "}
            {emoji}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            onClick={() => linkAction(url)}
            style={{ width: "250px" }}
          >
            {cta}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default UpsellModal;
