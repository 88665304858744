const Utils = {
  toUpperCase(str: string): string {
    if (str.length === 0) {
      return str;
    }

    const firstLetter: string = str.charAt(0);
    const restOfStr: string = str.slice(1);

    return firstLetter.toUpperCase() + restOfStr;
  },
};

export default Utils;
